body {
	margin: 0;
	font-size: 14px;
	font-family: 'Roboto';
}

.column {
	float: left;
	position: relative;
	/* border-style: solid;
	border-width: 1px; */
}

.left {
	float: left;
}

.right {
	float: right;
}

.center {
  float: right;
  width: fit-content;
  position: relative;
  left: -50%;
  border: 3px solid green;
}

/* Clear floats after the columns */
.row{
	/* border-style: solid;
	border-width: 1px; */
}
.row:after {
	content: "";
	display: table;
	clear: both;
}

.rowPadding {
	padding:5px;
}

.avatar {
	float: left;
}

.mainMenu{
	background-color: #73adde;
	height:30px;
	color:white;
	border-bottom: 1px solid white;
	padding:2px;
}

.customerList{
	overflow-y: scroll;
	height:95%;
}
.custListItem, .custListItemSelected, .custListItemSelected2{
	height: 26px;
	/* background-color: #fbfbfb; */
	padding-left: 8px;
	padding-top: 8px;
	border-bottom: 1px solid lightgray;
	cursor: pointer;
}
.custListItemSelected{
	/* background-color: #3a95e4; */
    background-color: #c0e0ff;
	/* color:white */
}
.custListItemSelected2{
	background-color: #d8d8d8;
	color:black
}
.custListItem:hover { 
	background-color: #efefef;
}

.custSearchField{
	height: 33px !important;
	width:100%;
	border-left: none !important;
	/* margin-bottom: 1px; */
}
.custListLabel{
	margin-left:45px;
}

.custDetailHeader {
	height: 25px;
	line-height: 26px;
    background-color: #c0e0ff;
	/* border-bottom: 1px solid black; */
    font-size: 20px;
    padding: 5;
}

.custDetailsMainDiv{
	/* min-width: 600px; */
	/* overflow-y: scroll;
	overflow-x: scroll; */
}
.custDetailLabel{
	margin-left:60px;
}

.custAgeLabel{
    font-size: 0.8em;
	margin-left: 20px;
	line-height: 25px;
}

.commonFormsLabel{
    height: 30px;
    line-height: 30px;
    background: #4c4c4c;
    color: white;
    margin-top: 10px;
    padding-left: 10px;
    font-style: italic;
	font-size: 14px;
    font-weight: bold;
    border-bottom:1px solid black;
}

.miniIcons{
	width:20px;
	height:20px;
	color:#717171;
}

.hidden { display:none; }

/* Divider */
.Resizer {
	background: #000;
	opacity: .2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

 .Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

 .Resizer.horizontal {
	height: 11px;
	margin: -5px 0;
	border-top: 5px solid rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-top: 5px solid rgba(0, 0, 0, 0.5);
	border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
	width: 11px;
	margin: 0 -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid rgba(0, 0, 0, 0.5);
	border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
/* Divider */

.pro-sidebar {
	z-index: 9 !important;
}

.crypt-key {
	float: right;
	font-size: large;
	font-variant-numeric: slashed-zero;
	background: royalblue ;
	font-family: roboto ;
	color: white ;
	padding: 4px 15px ;
	/* cursor: pointer; */
}
/* Overlay Effect */
#overlay {
	position: absolute; /* Sit on top of the page content */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(0 0 0 / 2%); /* Black background with opacity */
	z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
	cursor: pointer; /* Add a pointer on hover */
  }
#overlayText {
	color: gray;
    left: 40%;
    position: absolute;
    top: 40%;
    font-size: 2em;
    font-weight: bold;
}